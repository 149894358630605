<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{
            $t("Add a Day")
          }}</span>
          <v-spacer />
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 400px;"
      >
        <v-row class="pt-5">
          <v-col cols="12">
            <v-card
              v-for="(fm, index) in item.days"
              :key="index"
              class="mt-3"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="11"
                    md="11"
                  >
                    <v-select
                      v-model="fm.day"
                      :rules="[validators.required]"
                      :error-messages="fm.error_day"
                      :label="$t('Day')"
                      item-text="name"
                      item-value="id"
                      :items="days.filter(el => (fm.day && fm.day === el.id) || !item.days.map(ele => ele.day ).includes(el.id))"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      @input="fm.error_day = null"
                    />
                  </v-col>
                  <v-col
                    cols="1"
                    md="1"
                  >
                    <v-icon
                      color="red"
                      @click.stop="removeDay(index, day)"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="fm.start"
                      :rules="[validators.required]"
                      :error-messages="fm.error_start"
                      min="0"
                      max="22"
                      dense
                      hide-details
                      type="number"
                      outlined
                      :label="$t('Opening hour')"
                      @input="fm.error_start = null"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="fm.end"
                      :rules="[validators.required]"
                      :error-messages="fm.error_end"
                      dense
                      hide-details
                      type="number"
                      min="1"
                      max="23"
                      outlined
                      :label="$t('Closing hour')"
                      @input="fm.error_end = null"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-autocomplete
                      v-model="fm.meals_ids"
                      :rules="[validators.required]"
                      :error-messages="errorMessages.meals_ids"
                      :label="$t('Meals')"
                      item-text="name"
                      item-value="id"
                      :items="meals"
                      :search-input.sync="search"
                      :loading="isLoadingMeals"
                      multiple
                      chips
                      deletable-chips
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    >
                      <template #no-data>
                        <span>{{ $t("Meal") }} <strong>{{ search }}</strong> {{ $t("Doesn't exist.") }}</span>
                        <i-form
                          v-model="meal"
                          :object="meal"
                          :is-form-active="isFormActive"
                          :headers="tableColumns.filter(ele => ele.fields )"

                          :config="{url: '/admin/meals', title: $t('Add Meal'),
                                    btnText: 'Create Meal',
                                    successMessage: $t('Food successfully saved.'),
                                    successMessage: $t('Error saving Meal.'),
                                    width: '700px',
                          }"
                          @cancel="isFormActive = false"
                          @saved="updatemeals"
                        >
                        </i-form>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <v-btn
            :loading="loading"
            :disabled="item.days.length > 6"
            color="info"
            class="me-3"
            @click="addDay"
          >
            {{ $t("Add Day") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiPencilOffOutline,
  mdiCalendar,
  mdiCloudUploadOutline,
} from '@mdi/js'
import {
  getIndex,
  getItem,
} from '@core/utils'
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  required,
  emailValidator,
} from '@core/utils/validation'
import axios from '@axios'
import {
  format,
} from 'date-fns'
import {
  avatarText,
} from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'
import mealController from '@/views/apps/meals/Controller'
import IForm from '@/components/IForm'
import IAutocomplete from '@/components/IAutocomplete'
import { i18n } from '@/plugins/i18n'

export default {
  components: {
    IAutocomplete,
    IForm,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {
      },
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, {
    emit,
  }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()

    const {
      tableColumns,
    } = mealController()

    const errorMessages = ref({})
    const valid = ref(false)
    const isFormdayActive = ref(false)
    const loading = ref(false)
    const meals = ref([])
    const isLoadingMeals = ref(false)
    const days = ref([
      { id: 1, name: i18n.t('Monday') },
      { id: 2, name: i18n.t('Tuesday') },
      { id: 3, name: i18n.t('Wednesday') },
      { id: 4, name: i18n.t('Thursday') },
      { id: 5, name: i18n.t('Friday') },
      { id: 6, name: i18n.t('Saturday') },
      { id: 7, name: i18n.t('Sunday') },
    ])
    const day = ref({})
    const isLoadingDays = ref(false)
    const search = ref('')
    const minDate = new Date().toISOString().substr(0, 10)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    const meal = computed({
      get: () => ({ name: search.value, user_id: item.value.id }),
    })

    item.value.days = item.value.days || []

    watch(item, val => {
      if (item.value.user && days.value.findIndex(ele => ele.id === item.user_id) < 0) {
        // days.value.push(item.value.user)
      }
    }, {
      deep: true,
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const removeDay = (index, day) => {
      item.value.days.splice(index, 1)

      /* if (day.id != null) {
        axios.post(`/meals/action/delete_day/${item.value.id}`, { day_id: day.id })
      } */
    }
    const addDay = () => {
      item.value.days = item.value.days || []
      item.value.days.push({ start: 0, end: 23 })
    }
    const formatDate = (date, f) => format(date, f)
    const newDay = () => {
      item.value.user = {
        name: search.value, email: null, tell: null,
      }
      isFormdayActive.value = true
    }

    const onSubmit = () => {
      if (valid.value) {
        for (let i = 0; i < item.value.days.length; i++) {
          if (!item.value.days[i].day || item.value.days[i].day == null) {
            isSnackbarBottomVisible.value = true
            snackBarMessage.value = i18n.t('Please enter a valid day')
            item.value.days[i].error_day = i18n.t('Please enter a valid day')

            return false
          }
          if (item.value.days[i].start < 0 || item.value.days[i].start > 22) {
            isSnackbarBottomVisible.value = true
            snackBarMessage.value = i18n.t('Please enter a valid opening hour between [0 - 22]')
            item.value.days[i].error_start = i18n.t('Please enter a valid opening hour between [0 - 22]')

            return false
          }
          if (item.value.days[i].end < 1 || item.value.days[i].start > 23) {
            isSnackbarBottomVisible.value = true
            snackBarMessage.value = i18n.t('Please enter a valid closing hour between [1 - 23]')
            item.value.days[i].error_end = i18n.t('Please enter a valid closing hour between [0 - 22]')

            return false
          }
          if (item.value.days[i].start > item.value.days[i].end) {
            isSnackbarBottomVisible.value = true
            snackBarMessage.value = i18n.t('Opening can\'t be greater than closing hour')
            item.value.days[i].error_start = i18n.t('Opening can\'t be greater than closing hour')

            return false
          }
        }
        loading.value = true
        axios.post('/admin/users', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = {
                  ...response.data.errors,
                }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            item.value = response.data

            // emit('update:is-form-active', false)

            emit('cancel')
            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = {
                ...error.data.errors,
              }
            }
          })
      } else {
        validate()
      }
    }

    const searchMeals = term => {
      axios.get('/admin/meals', { params: { term, rowsPerPage: 100, user: item.value.user_id || 0 } }).then(response => {
        isLoadingMeals.value = false

        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = meals.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            meals.value.push(u)
          }
        }
      })
    }
    const updatemeals = m => {
      isFormdayActive.value = false
      meals.value.push(m)
    }

    searchMeals('')

    // onMounted(searchParticular)
    watch(search, val => {

      if (!val || val.trim().length < 3 || isLoadingMeals.value) return ''
      isLoadingMeals.value = true
      searchMeals(val)

      return ''
    })

    // onMounted(searchParticular)

    return {
      day,
      minDate,
      resetItem,
      form,
      isFormdayActive,
      search,
      isLoadingMeals,
      updatemeals,
      meals,
      meal,
      days,
      newDay,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      tableColumns,
      item,
      valid,
      loading,
      validate,
      avatarText,
      addDay,
      removeDay,
      isLoadingDays,

      // validation
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPlus,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
